import {QbFetchStatus} from './qb-fetch-status'

export class QbFetch {
    #url
    #controller
    #signal
    #timeout
    #timeout_handle

    #request
    #requestBody
    #requestHeaders

    #response
    #responseObject = {}

    get #responseBody() {
        if(!this.responseObject.body) this.responseObject.body = {}
        return this.responseObject.body;
    }

    #exception

    #debug
    #promise
    #bodyPromise = Promise.withResolvers();


    #urlAppendSearchParams(param) {
        this.#url.searchParams = new URLSearchParams([...this.#url.searchParams.entries(), ...new URLSearchParams(param).entries()]);
    }

    #parseOptions(...args) {
        const options = {};
        this.#url = new URL(location.origin)
        for(let arg of args) {
            switch(arg?.constructor?.name) {
                case "Location":
                case "URL":
                    this.#url = new URL(arg);
                    break;
                case "String":
                case "Number":
                    arg = arg.toString();
                    if(/https?:\/\//.test(arg)) this.#url = new URL(arg);
                    else {
                        if(arg.startsWith("/")) this.#url.pathname = "/";
                        const m = arg.match(/^([^?#]*)(\?[^#])?(#.+)?$/);
                        if(!m) continue;
                        if(m[1]) this.#url.pathname = `${this.#url.pathname}/${m[1]}`.replace(/\/+/g, "/");
                        if(m[2]) this.#urlAppendSearchParams = m[2];
                        if(m[3]) this.#url.hash = m[3];
                    }
                    break;
                default:
                    if(typeof arg === "object") Object.assign(options, arg);
                    break;
            }
        }
        return options;
    }

    #writeDOC;

    constructor(...args) {
        const {debug, timeout, headers, body, method, writeDOC, ...options} = this.#parseOptions(...args);
        this.#url = this.#url.toString();
        if(typeof debug === 'boolean') this.#debug = debug;

        this.#controller = new AbortController();
        this.#signal = this.#controller.signal;
        if(typeof timeout === "number") this.timeout = timeout;

        this.#requestHeaders = new Headers(headers || {});
        if(typeof document !== "undefined" && !this.#requestHeaders.get('X-CSRF-Token')) {
            const csrf_token = document.head.querySelector('meta[name="csrf-token"]')?.content;
            if(csrf_token) this.#requestHeaders.set('X-CSRF-Token', csrf_token);
        }

        this.#requestBody = body;
        this.#writeDOC = writeDOC;

        this.#request = Object.assign({
                method: (method || "get").toUpperCase(),
                cache: 'no-cache'
            },
            options,
            {
                signal: this.#signal,
                mode: 'cors',
                credentials: 'same-origin',
                redirect: 'follow',
                // redirect: 'manual',
                // referrerPolicy: 'no-referrer'
            });

        this.#exception = new QbFetchStatus(this);
        this.#promise = this.#fetch().catch(e => {
            if(!(e instanceof QbFetchStatus)) this.#exception.error = e;
            if(this.#debug) console.error(this.#exception, this.#exception.cause);
            return this;
        });
    }

    then(cb) {
        return this.#promise.then(()=>cb(this));
    }

    result({resolved, redirected, fragment, wholePage, ...options} = {}) {
        this.#promise.then(() => {
            if(resolved) resolved(this);
            if(redirected && this.location) redirected(this.location, this);
            if(fragment || wholePage && typeof this.#responseBody.html === "function") {
                this.#responseBody.html().then(innerHTML => {
                    if(/<!DOCTYPE/.test(innerHTML)) {
                        if(wholePage) wholePage(innerHTML, this);
                        return;
                    }
                    if(fragment) fragment(Object.assign(document.createElement("template"), {innerHTML}).content, this);
                });
            }
            for(const [option, callback] of Object.entries(options)) {
                if(isNaN(option) || this.#response.status != option) continue;
                if(callback(this) !== false) return;
            }

            for(const [option, callback] of Object.entries(options)) {
                if(!Object.hasOwn(this.#responseBody, option)) continue;
                const response = this.#responseBody[option]();
                if(response instanceof Promise) response.then(data => callback(data, this));
                else callback(response, this)
            }
        });
        return this;
    }

    get url() { return new URL(this.#url) }

    get ok() { return this.#response?.ok }

    get redirected() { return this.#response?.redirected && this.#response?.url }

    get status() { return this.#response?.status }

    get statusText() { return this.#response?.statusText }

    get method() { return this.#request.method }

    get location() { return this.header("location") }

    header(key) { return this.responseObject.headers?.get(key) }

    get requestObject() {
        const rv = {headers: this.#requestHeaders, ...this.#request}
        if(this.#requestBody) rv.body = this.#requestBody;
        return rv;
    }

    get responseObject() { return this.#responseObject }

    get body() {
        throw "Body is not yet resolved"
    }

    get content_type() {
        return this.#response.headers.get("content-type")?.replace(/;.*$/, '');
    }

    get #options() {
        let body = this.#requestBody;
        const headers = this.#requestHeaders;
        if(body && body.constructor.name === "Object" || headers.get('Content-Type') === 'application/json') {
            headers.set('Content-Type', 'application/json');
            body = JSON.stringify(body);
        }
        const options = {body, headers, ...this.#request};
        if(this.#signal) options.signal = this.#signal;
        return options;
    }

    #readStream(body) {
        const reader = body.getReader();
        return new ReadableStream({
            start(controller) {
                return pump();
                function pump() {
                    return reader.read().then(({done, value}) => {
                        if(done) return controller.close();
                        controller.enqueue(value);
                        return pump();
                    });
                }
            }
        });
    }

    async #fetch() {
        let url = this.url.href;
        // if(this.url.origin === location.origin) url = url.replace(this.url.origin, '');
        // else
        this.#request.redirect = "follow";
        this.#response = await fetch(url, this.#options);
        const response = this.#response.clone();
        // switch(response.status) {
        //     case 302:
        //     case 303:
        //         this.#request.method = "GET";
        //     case 307:
        //         this.#url = this.#response.headers.get('Location');
        //         return this.#fetch();
        // }

        if(response.url !== this.#url) this.#responseObject.url = response.url;

        for(const k in response) {
            if(/^(headers|bodyUsed|ok|redirected|status(Text)?|type)$/.test(k)) this.#responseObject[k] = response[k];
        }

        if(this.location) return location = this.location;

        if(response.headers.has('x-chat')) {
            setTimeout(() => {
                try {
                    let notifications = JSON.parse(response.headers.get('x-chat'));
                    if(!(notifications[0] instanceof Array)) notifications = [notifications]
                    for(const [type, ...data] of notifications) {
                        if(type in self) self[type]?.call(self, data);
                        else switch(type) {
                            case "notice":
                                console.log(type, ...data);
                                break;
                            case "exception":
                                console.warn(type, ...data);
                                break;
                            case "error":
                                console.error(type, ...data);
                                break;
                        }
                    }
                }
                catch(e) {}
            }, 10);
        }
        switch(this.content_type) {
            case "application/json":
                this.#responseBody.json = ()=>response.json();
                return;
            case "text/plain":
                this.#responseBody.text = ()=>response.text();
                return;
            case "text/html":
                this.#responseBody.html = ()=>response.text();
                return;
        }
        switch(response.body?.constructor?.name) {
            case "ReadableStream":
                this.#responseBody.stream = ()=>response.body;
                this.#responseBody.blob = ()=>new Response(this.#readStream(response.body)).blob();
                this.#responseBody.objectUrl = ()=>this.#responseBody.blob().then(blob=>URL.createObjectURL(blob))
                return;
            // case "Blob":
            //     this.#responseBody.blob = ()=>response.blob();
            //     break;
            case "String":
                return this.#responseBody.text = ()=>response.body;
            default:
                throw new TypeError(`Don't know what to do with ${response.body?.constructor?.name}`);
        }
    }

    get abort() {
        this.#controller.abort();
    }

    set timeout(value) {
        clearTimeout(this.#timeout_handle);
        this.#timeout_handle = setTimeout(() => this.abort, this.#timeout = value);
    }
}
self.QbFetch = (...args)=>new QbFetch(...args);


// GET(path, {slot, media}).result({
//     objectUrl: f=>console.log("objectUrl", f),
//     html: f=>console.log("html", f),
//     blob: f=>console.log("blob", f),
//     fragment: f=>console.log("fragment", f),
//     text: f=>console.log("text", f),
//     json: f=>console.log("json", f),
//     stream: f=>console.log("stream", f),
// });




